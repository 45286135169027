<template>
    <div
        v-if="value"
        class="free-answer-process"
    >
        <v-textarea
            :label="$trans('questions.hints.free_answer_get')"
            outlined
            hide-details
            @input="onReply"
        />
    </div>
</template>

<script>
import questionsTypeBasePropsMixin from './mixins/questionsTypeBasePropsMixin'
export default {
    name: 'FreeAnswerProcess',
    mixins: [questionsTypeBasePropsMixin],
    data: () => ({
        result: {
            reply: '',
            passed: false
        }
    }),
    methods: {
        onReply(value) {
            const result = this.result

            result.reply = value
            result.passed = result.reply.length > 0

            let process = null

            if(result.passed) {
                process = {
                    options: this.value,
                    score: this.score,
                    defaultScore: this.score,
                    ...result
                }
            }

            this.$emit('process', process)
        }
    }
}
</script>

<style lang=scss>

</style>
